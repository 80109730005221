import { InputBlueBottom } from 'components/InputControls.tsx/Inputs';
import "react-datepicker/dist/react-datepicker.css";
import 'moment/locale/fr'; // without this line it didn't work
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import dayjs, { Dayjs } from 'dayjs';
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import moment from 'moment';
import { SectionTitle } from 'components/StyledWrappers/SubscriptionWrappers';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import CustomSelect from 'components/InputControls.tsx/CustomSelect';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Checkbox, Typography } from '@mui/material';
moment.locale('fr')




const UserWrapper = styled.div`
  margin-bottom: 50px;
  /* margin-top:50px ; */
`;
const Label = styled.p`
  font-size: 14px;
  color: red;
`;

const DatePickerWrapper = styled.div`
padding-top:10px;
`
interface UserProps {
  setter: (params: any) => any;
  userData: UserDataType;
  errors: UserDataType;
  userIndex: number;
  users: any;
  isPhoneRequired?: boolean;
  isEmailRequired?: boolean;
}

interface UserDataType {
  birthDate: string;
  email: string;
  firstname: string;
  lastname: string;
  phoneNumber: string;
  ssn: string;
  gender: string;
  externalRef?: string;

}

function User({ setter, userData, errors, userIndex, users, isPhoneRequired, isEmailRequired }: UserProps) {
  let today = new Date()

  let initialFields = {
    email: '',
    firstname: '',
    lastname: '',
    phoneNumber: '',
    ssn: '',
    gender: '',
    birthDate: "",
    hellocare: false,
    anamnese: false,
    externalRef: ""
  }
  const [userDetails, setuserDetails] = useState(initialFields)
  const [value, setValue] = React.useState('');
  const [helloCareAccount, sethelloCareAccount] = useState(false);
  const [anamneseAccount, setanamneseAccount] = useState(false);
  const handleGenderChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue((event.target as HTMLInputElement).value);
    setuserDetails({
      ...userDetails, ["gender"]: (event.target as HTMLInputElement).value
    })
    // create a copy of myArray
    let newArray = [...users];

    // update specific object's specific key
    newArray[userIndex]["gender"] = (event.target as HTMLInputElement).value;

    // update state with new Array
    setter(newArray)
  };

  useEffect(() => {
    setuserDetails(initialFields)
    setValue('')
    setDateValue(dayjs(today))
  }, [userIndex])



  const [dateValue, setDateValue] = React.useState<Dayjs | null>(
    dayjs(today),
  );

  // const handleDateChange = (newValue: Dayjs | null) => {
  //   setDateValue(newValue);
  // };
  const handleDateChange = (newValue: Dayjs | null) => {
    console.log("handleDateChange is fired ***************")
    const formattedDate = newValue ? dayjs(newValue).format('YYYY-MM-DD') : '';

    setDateValue(newValue);
    if (dateValue) {
      setuserDetails({
        ...userDetails, ["birthDate"]: formattedDate
      })
      // create a copy of myArray
      let newArray = [...users];

      // update specific object's specific key
      newArray[userIndex]["birthDate"] = formattedDate

      // update state with new Array
      setter(newArray)
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const target = event.target;
    const name = target.name;
    const value = target.value;
    setuserDetails({
      ...userDetails, [name]: value
    })
    // create a copy of myArray
    let newArray = [...users];

    // update specific object's specific key
    newArray[userIndex][name] = value;

    // update state with new Array
    setter(newArray)
  }
  function onChangeHellocare(e: React.ChangeEvent<HTMLInputElement>) {
    sethelloCareAccount(e.target.checked);
    setuserDetails({
      ...userDetails, "hellocare": e.target.checked
    })
    // create a copy of myArray
    let newArray = [...users];

    // update specific object's specific key
    newArray[userIndex]["hellocare"] = e.target.checked;

    // update state with new Array
    setter(newArray)
  }
  function onChangeAnamnese(e: React.ChangeEvent<HTMLInputElement>) {
    setanamneseAccount(e.target.checked);
    setuserDetails({
      ...userDetails, ["anamnese"]: e.target.checked
    })
    // create a copy of myArray
    let newArray = [...users];

    // update specific object's specific key
    newArray[userIndex]["anamnese"] = e.target.checked;

    // update state with new Array
    setter(newArray)
  }

  // useEffect(() => {
  //   if (dateValue && dateValue.toISOString().length >= 10) {
  //     setuserDetails({
  //       ...userDetails, ["birthDate"]: dateValue.toISOString().slice(0, 10)
  //     })
  //     // create a copy of myArray
  //     let newArray = [...users];

  //     // update specific object's specific key
  //     newArray[userIndex]["birthDate"] = dateValue.toISOString().slice(0, 10)

  //     // update state with new Array
  //     setter(newArray)
  //   }
  // }, [dateValue])


  return (

    <UserWrapper style={{}} >
      <SectionTitle>Bénéficiaire {userIndex + 1}</SectionTitle>
      <InputBlueBottom
        type="text"
        name="lastname"
        value={userDetails.lastname}
        onChange={(e) => handleChange(e)}
        placeholder="Nom"
        required={true}
      />
      <Label>{errors.lastname}</Label>
      <InputBlueBottom
        type="text"
        name="firstname"
        value={userDetails.firstname}
        onChange={(e) => handleChange(e)}
        placeholder="Prénom"
        required={true}

      />
      <Label>{errors.firstname}</Label>

      {/* <DatePickerWrapper>
        <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="fr">
          <DesktopDatePicker
           //@ts-ignore
            label="Date de naissance"
            format="DD/MM/YYYY"
            value={dateValue}
            onChange={handleDateChange}
            maxDate={dayjs(today)}
            sx={{
              '& label.Mui-focused': {
                color: '#035282',
              },
              '& .MuiInput-underline:after': {
                borderBottomColor: '#035282',
              },
              '& .MuiOutlinedInput-root': {
                '&.Mui-focused fieldset': {
                  borderColor: '#035282',
                },
              },
              '& .MuiInputBase-input': {

                fontFamily: [
                  'open sans',

                ].join(','),

              },

              '& .MuiIconButton-root.MuiInputAdornment-root.MuiInputAdornment-positionEnd:not(.Mui-disabled)::before': {
                display: 'none',
              },
            }}
          />
        </LocalizationProvider>
      </DatePickerWrapper> */}
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr">
        <DemoContainer
          components={[
            'DatePicker',
            'MobileDatePicker',
            'DesktopDatePicker',
            'StaticDatePicker',
          ]}
        >

          <DatePicker
            //@ts-ignore
            label="Date de naissance"
            format="DD/MM/YYYY"
            value={dateValue}
            onChange={handleDateChange}
            defaultValue={dayjs('2022-04-17')}
            sx={{
              '& label.Mui-focused': {
                color: '#035282',
              },
              '& .MuiInput-underline:after': {
                borderBottomColor: '#035282',
              },
              '& .MuiOutlinedInput-root': {
                '&.Mui-focused fieldset': {
                  borderColor: '#035282',
                },
              },
              '& .MuiInputBase-input': {

                fontFamily: [
                  'open sans',

                ].join(','),

              },

              '& .MuiIconButton-root.MuiInputAdornment-root.MuiInputAdornment-positionEnd:not(.Mui-disabled)::before': {
                display: 'none',
              },
            }}
          />

        </DemoContainer>
      </LocalizationProvider>

      <Label>{errors.birthDate}</Label>
      <CustomSelect
        value={value}
        onChange={handleGenderChange}
        label='Genre'
        items={[{ value: "Homme", label: "Homme" }, { value: "Femme", label: "Femme" }]}


      />
      <Label>{errors.gender}</Label>
      <InputBlueBottom
        type="email"
        value={userDetails.email}
        name="email"
        onChange={(e) => handleChange(e)}
        placeholder="Email"
        required={isEmailRequired== false ? false : true}

      />
      <Label>{errors.email}</Label>



      <InputBlueBottom
        type="text"
        name="phoneNumber"
        value={userDetails.phoneNumber}
        onChange={(e) => handleChange(e)}
        placeholder="Numéro de téléphone"
        required={isPhoneRequired == false ? false : true}

      />
      <Label>{errors.phoneNumber}</Label>

      <InputBlueBottom
        type="text"
        name="ssn"
        value={userDetails.ssn}
        onChange={(e) => handleChange(e)}
        placeholder="Numéro de sécurité sociale"
        required={true}
      />
      <Label>{errors.ssn}</Label>
      <InputBlueBottom
        type="text"
        name="externalRef"
        value={userDetails.externalRef}
        onChange={(e) => handleChange(e)}
        placeholder="Identifiant externe"
      />
      <Label>{errors.externalRef}</Label>
      <div style={{ display: "flex", flexDirection: "row", alignItems: "center", flex: 6, }}>
        <Checkbox
          sx={{
            "&.Mui-checked": {
              color: '#18C5BF'
            }
          }}
          checked={userDetails.anamnese}
          onChange={(e) => { onChangeAnamnese(e) }}
          inputProps={{ 'aria-label': 'controlled' }}

        />
        <Typography>
          Activer la téléconsultation
        </Typography>
      </div>

      <div style={{ display: "flex", flexDirection: "row", alignItems: "center", flex: 6, }}>
        <Checkbox
          sx={{
            "&.Mui-checked": {
              color: '#18C5BF'
            }
          }}
          checked={userDetails.hellocare}
          onChange={(e) => {
            onChangeHellocare(e)
          }}
          inputProps={{ 'aria-label': 'controlled' }}

        />
        <Typography>
          Activer profil patient Anamnèse
        </Typography>
      </div>
    </UserWrapper >

  )


}


export default User;
