import moment from 'moment';

export const validatePatient = (values: any, setUserErrors: any, checkLogins: boolean = true) => {
  console.log('Recieved values to test', values);
  const errors = {
    email: '',
    firstname: '',
    lastname: '',
    phoneNumber: '',
    ssn: '',
    gender: '',
    birthDate: '',
    externalRef: ''
  };
  const phoneRegex = /^(?:(?:\+|00)33|0)\s*[1-9](?:[\s.-]*\d{2}){4}$/;
  const emailRegex = /$^|.+@.+..+/;
  const ssnRegex = /^(\d{13}|\d{15}|\s*)?$/;
  if (checkLogins == true && 'email' in values) {
    errors.email = emailRegex.test(values.email) && values.email !== '' ? '' : 'Email non valide.';
  }
  if ('firstname' in values) {
    errors.firstname = values.firstname ? '' : 'Ce champs est obligatoire';
  }

  if ('lastname' in values) {
    errors.lastname = values.lastname ? '' : 'Ce champs est obligatoire';
  }
  if (checkLogins == true && 'phoneNumber' in values) {
    errors.phoneNumber = phoneRegex.test(values.phoneNumber) ? '' : 'Entrer un numéro de téléphone valide';
  }

  if ('ssn' in values) {
    errors.ssn = ssnRegex.test(values.ssn) && values.ssn.length > 0 ? '' : 'Ce champs est obligatoire';
    console.log('errors.ssn', errors.ssn);
  }
  if ('gender' in values) {
    errors.gender = values.gender ? '' : 'Ce champs est obligatoire';
  }
  if ('birthDate' in values) {
    errors.birthDate = moment(new Date(values.birthDate)).isBefore(moment().subtract(10, 'years'), 'year')
      ? ''
      : 'Veuillez entrer une date valide';
  }
  console.log('errors', errors);
  setUserErrors(errors);
  return Object.values(errors).every((x) => x === '');
};
